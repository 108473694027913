<template>
    <div class="PickUpSelfSku">
        <el-dialog
            class="PickUpSelfSkuDialog"
            title="查询商品基本信息"
            :visible.sync="dialogFormVisible"
            width="80%"
            top="60px"
            @open="handleQuery"
        >
            <el-card shadow="never" style="margin-top: 8px">
                <el-form
                    @submit.native.prevent
                    :inline="true"
                    style="text-align: left"
                    v-model="form"
                    ref="queryForm"
                    size="small"
                >
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="查询条件">
                                <el-input placeholder="名称/条码" v-model="form.search" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="类目">
                                <el-select placeholder="类目" v-model="form.categoryCode" filterable>
                                    <el-option label="请选择" value="" />
                                    <el-option-group
                                        v-for="group in meta.categories"
                                        :label="group.name"
                                        :key="group.id"
                                    >
                                        <el-option
                                            v-for="category in group.sub"
                                            :label="category.name"
                                            :value="category.code"
                                            :key="category.code"
                                        />
                                    </el-option-group>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="品牌">
                                <el-select placeholder="品牌" v-model="form.brandCode" filterable>
                                    <el-option label="请选择" value="" />
                                    <el-option
                                        v-for="bd in meta.brands"
                                        :label="bd.name"
                                        :value="bd.code"
                                        :key="bd.code"
                                    />
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="商品类型">
                                <template v-if="canChangeType()">
                                    <el-select placeholder="商品类型" v-model="form.type">
                                        <el-option label="请选择" value="" />
                                        <el-option label="标品" :value="0" />
                                        <el-option label="非标品" :value="1" />
                                    </el-select>
                                </template>
                                <template v-else>
                                    <el-input readonly :value="skuType(form.type)" />
                                </template>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item>
                                <el-button type="primary" @click="searchHandleQuery" size="small">查询</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-card>

            <el-card shadow="never" style="margin-top: 8px" align="left">
                <div style="height: 440px; width: 100%">
                    <el-table
                        id="pickUpSelfSkuTable"
                        border
                        ref="pickUpSelfSkuTable"
                        stripe
                        style="width: 100%"
                        :data="tableData"
                        size="mini"
                        :highlight-current-row="true"
                        max-height="440"
                        :key="tableKey"
                    >
                        <el-table-column width="40" type="selection" />
                        <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                        <el-table-column
                            prop="name"
                            label="商品名称"
                            width="180"
                            fixed="left"
                            :show-overflow-tooltip="true"
                        />
                        <el-table-column
                            prop="bars"
                            label="条码"
                            width="120"
                            fixed="left"
                            :show-overflow-tooltip="true"
                        >
                            <template slot-scope="scope">
                                <span>{{ scope.row.bars.map((d) => d).join(' / ') }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="specs" label="规格" width="120" />
                        <el-table-column prop="type" label="类型" width="80">
                            <template slot-scope="scope">
                                <span>{{ scope.row.type | skuTypeDesc }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="status" label="状态" width="80">
                            <template slot-scope="scope">
                                <span>{{ scope.row.status | statusDesc }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="category" label="类目" width="100" />
                        <el-table-column prop="brand" label="品牌" width="100" :show-overflow-tooltip="true" />
                        <el-table-column prop="unit" label="单位(小)" width="80" />
                        <el-table-column prop="expirationDate" label="保质期" width="80" />
                        <el-table-column prop="creator" label="创建人" width="120" />
                        <el-table-column prop="createTime" label="创建时间" width="140" />
                    </el-table>
                </div>
                <el-pagination
                    :page-sizes="page.pageSizes"
                    :page-size="form.limit"
                    :layout="page.PageStyle"
                    :total="page.total"
                    :current-page="form.page"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    @prev-click="handlePrevClick"
                    @next-click="handleNextClick"
                />
            </el-card>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmSelection">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Util from 'js/Util';
import GoodsCommon from 'js/GoodsCommon';
import UrlUtils from '../../js/UrlUtils';

export default {
    name: 'PickUpSelfSku',
    data() {
        return {
            form: {
                search: '',
                categoryCode: '',
                brandCode: '',
                type: '',
                page: 1,
                limit: Util.Limit,
            },
            tableKey: '',
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            meta: {
                brands: [],
                categories: [],
            },
            url: {
                page: '/goods/sku/pageExtend',
            },
            tableData: [],
            selected: [],
            dialogFormVisible: false,
        };
    },
    filters: {
        bar(bars) {
            if (bars && bars.length) {
                return bars.join('/');
            }
            return bars;
        },
        skuTypeDesc(type) {
            return GoodsCommon.skuTypeDesc(type);
        },
        statusDesc(status) {
            return GoodsCommon.goodsStatusDesc(status);
        },
    },
    watch: {
        dialogFormVisible() {
            if (!this.dialogFormVisible) {
                this.form.search = '';
                this.form.categoryCode = '';
                this.form.brandCode = '';
                this.form.type = '';
                this.form.page = 1;
                this.form.limit = Util.Limit;
            }
        },
    },
    mounted() {
        this.initPageData();
    },
    methods: {
        canChangeType() {
            return Util.isEmpty(this.form.type);
        },
        skuType() {
            return GoodsCommon.skuTypeDesc(this.form.type);
        },
        show(queryParam, url) {
            if (!Util.isEmpty(url)) {
                this.url.page = url;
            }
            Util.copyProperties(queryParam, this.form);
            this.dialogFormVisible = true;
        },
        initPageData() {
            const _this = this;
            UrlUtils.GoodsBrands(_this, (data) => {
                _this.meta.brands = data;
            });
            UrlUtils.GroupedGoodsCategories(_this, (rst) => {
                _this.meta.categories = rst;
            });
        },
        searchHandleQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        handleQuery() {
            this.$nextTick(() => {
                const _this = this;
                const _params = { params: _this.form };
                Util.queryTableFlag(_this, _this.url.page, _params, null);
            });
        },
        confirmSelection() {
            //关闭窗口
            this.dialogFormVisible = false;
            this.$emit('ok', this.$refs['pickUpSelfSkuTable'].selection);
        },
        handleSizeChange(size) {
            this.form.page = 1;
            this.form.limit = size;
            this.handleQuery();
        },
        handleCurrentChange(page) {
            this.form.page = page;
            this.handleQuery();
        },
        handlePrevClick(page) {
            this.form.page = page;
        },
        handleNextClick(page) {
            this.form.page = page;
        },
        handleSelectionChange(selections) {
            this.selected = selections;
        },
    },
};
</script>
<style scoped>
.PickUpSelfSku >>> .el-card__body {
    padding: 8px 8px 8px 8px;
}

.PickUpSelfSkuDialog >>> .el-dialog__body {
    padding: 10px 20px 10px 20px;
}
</style>
